import React, { Suspense } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import appSettings from '../../settings/AppSettings'
import { MasterInit } from '../../_metronic/layout/MasterInit'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'

import { FallbackView } from '../../_metronic/partials'
import { Error404Page } from '../modules/errors/Error404Page'
import authHelper from '../utils/authHelper'
import { ErrorsPage } from './../modules/errors/ErrorsPage'
const CustomerCourierLazy = React.lazy(() => import('../modules/customerCourier/Page'))
const CustomerCourierConnectPageLazy = React.lazy(() => import('../modules/customerCourierConnect/CustomerCourierConnectPage'))
const HomePageLazy = React.lazy(() => import('../modules/home/HomePage'));
const CustomerProfilePageLazy = React.lazy(() => import('../modules/customerProfile/UserProfilePage'));
const AddressBookPageLazy = React.lazy(() => import('../modules/customerAddressBook/AddressBookListPage'));
const OrderListLazy = React.lazy(() => import('../modules/orderList/OrderList'));
const OrderAddLazy = React.lazy(() => import('../modules/order/OrderAdd'));
const OrderEditLazy = React.lazy(() => import('../modules/order/OrderEdit'));
const OrderInfoLazy = React.lazy(() => import('../modules/orderInfo/OrderInfoPage'));
interface IPrivRoutesProps {
  path: string
  exact?: boolean | undefined
  children?: JSX.Element | JSX.Element[] | undefined
}

export const PrivRoute: React.FunctionComponent<IPrivRoutesProps> = ({
  path,
  children,
}: IPrivRoutesProps) => {
  let location = useLocation()
  //const isLoggedIn = useSelector(state => state.Auth.idToken);
  const isLoggedIn = authHelper.isAuthenticated()
  if (isLoggedIn) return <>{children}</>
  return (
    <Redirect
      to={{
        pathname: appSettings.routes.publicRoutes.signIn,
        state: { from: location },
      }}
    />
  )
}

export function PrivateRoutes() {
  return (
    <>
      <Suspense fallback={<FallbackView />}>
        <MasterLayout>
          <Switch>
            <Route
              exact={true}
              path={appSettings.routes.dashboard.index}
              component={HomePageLazy}
            />
            <Route
              exact={true}
              path={appSettings.routes.profile.index}
              component={CustomerProfilePageLazy}
            />
            <Route
              exact={true}
              path={appSettings.routes.order.list}
              component={OrderListLazy}
            />
            <Route
              exact={true}
              path={appSettings.routes.order.create}
              component={OrderAddLazy}
            />
            <Route
              exact={true}
              path={appSettings.routes.order.edit}
              render={(props) => <OrderEditLazy orderId={props.match.params.id} />}
            />
            <Route
              exact={true}
              path={appSettings.routes.order.info}
              component={OrderInfoLazy}
            />
            <Route
              exact={true}
              path={appSettings.routes.courier.index}
              component={CustomerCourierLazy}
            />
            <Route
              exact={true}
              path={appSettings.routes.courier.connect}
              component={CustomerCourierConnectPageLazy}
            />
            <Route
              exact={true}
              path={appSettings.routes.addressBook.index}
              component={AddressBookPageLazy}
            />

            <Route
              exact={true}
              path={'/account/location-mapping'}
              component={React.lazy(() => import('../modules/locationMapping/LocationMappingPage'))}
            />
            
            <Redirect exact={true} from='/' to={appSettings.routes.dashboard.index} />
            <Route component={Error404Page} />
          </Switch>
        </MasterLayout>
        <MasterInit />
      </Suspense>
    </>
  )
}
