import {PayloadAction} from '@reduxjs/toolkit'

import {all, call, put, takeLatest} from 'redux-saga/effects'

import {actions} from './reducer'
import customerService from '../../../services/customerService'
import {CustomerCourierWebhookSettingModel} from '../models'
import {BaseEntityResponse, BaseResponse} from '../../../models/model'

// type getInitDataSagaResponse = SagaReturnType<typeof  getInitDataAsync>;

function* modalInitLoadSaga(action: PayloadAction<{courierId: string; accountId: string}>) {
  try {
    yield put(actions.MODAL_LOADING(true))
    const {data, status, message}: BaseEntityResponse<CustomerCourierWebhookSettingModel> =
      yield call(customerService.getCustomerCourierWebhookSettingAsync, {
        courierId: action.payload.courierId,
        accountId: action.payload.accountId,
      })
    if (!status) {
      yield put(actions.MODAL_ERROR(message))
      yield put(actions.MODAL_LOADING(false))
      yield put(actions.RESET_MESSAGE_STATE())
      return
    }
    yield put(actions.MODAL_INIT_SET(data))
    yield put(actions.MODAL_LOADING(false))
  } catch (e) {
    console.error(e)
    yield put(actions.MODAL_LOADING(false))
    yield put(actions.MODAL_ERROR('Xảy ra lỗi.'))
    yield put(actions.RESET_MESSAGE_STATE())
  }
}

function* submitWebhookSettingSaga(action: PayloadAction<CustomerCourierWebhookSettingModel>) {
  try {
    const {data, status, message}: BaseResponse = yield call(
      customerService.submitWebhookSettingAsync,
      action.payload
    )
    debugger
    if (status === true) {
      yield put(actions.MODAL_LOADING(false))
      yield put(actions.MODAL_SUCCESS(message))
      yield put(actions.RESET_STATE(null))
      yield put(actions.MODAL_VISIBLE(false))
      return
    }
    yield put(actions.MODAL_ERROR(message))
    yield put(actions.MODAL_LOADING(false))
    yield put(actions.RESET_MESSAGE_STATE())
  } catch (e) {
    console.error(e)
    yield put(actions.MODAL_ERROR('Xảy ra lỗi.'))
    yield put(actions.MODAL_LOADING(false))
    yield put(actions.RESET_MESSAGE_STATE())
  }
}

function* generateLinkSaga(action: PayloadAction<{courierId: string; accountId: string}>) {
  try {
    yield put(actions.MODAL_LOADING(true))
    const {data, status, message}: BaseEntityResponse<CustomerCourierWebhookSettingModel> =
      yield call(customerService.generateWebhookSettingAsync, {
        courierId: action.payload.courierId,
        accountId: action.payload.accountId,
      })
    if (!status) {
      yield put(actions.MODAL_ERROR(message))
      yield put(actions.MODAL_LOADING(false))
      yield put(actions.RESET_MESSAGE_STATE())
      return
    }
    yield put(actions.GENERATE_LINK_SET(data))
    yield put(actions.MODAL_LOADING(false))
  } catch (e) {
    console.error(e)
    yield put(actions.MODAL_LOADING(false))
    yield put(actions.MODAL_ERROR('Xảy ra lỗi.'))
    yield put(actions.RESET_MESSAGE_STATE())
  }
}

export default function* customerCourierWebHookSettingSaga() {
  yield all([
    takeLatest(actions.setModalInitLoadAsync, modalInitLoadSaga),
    takeLatest(actions.submitWebhookSettingAsync, submitWebhookSettingSaga),
    takeLatest(actions.generateLinkAsync, generateLinkSaga),
  ])
}
