import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../../../setup/redux/Store'
import { TabProp } from '../../../commons/components/CustomTabController'
import { OrderStatusViewModal } from '../../../models/model/order-status.model'
import { OrderViewModel } from '../../../models/model/order.model'
import { GetOrderFromCourierResponse } from '../../../models/responses/order-from-courier.response'
// import {CustomerCourierWebhookSettingModel} from '../models'

export const initDetailOrderFromCourier: GetOrderFromCourierResponse = {
  rawData: "",
  courierId: "",
  trackingNo: "",
  statusName: "",
  totalWeight: "",
  dimension: "",
  pieceCount: 0,
  pieces: [],
  shipperName: "",
  shipperCountry: "",
  shipperStateProvince: "",
  shipperPostalCode: "",
  consigneeName: "",
  consigneeCountry: "",
  consigneeStateProvince: "",
  consigneePostalCode: "",
  orderStatus: [],
  deliveryStatus: [],
}

export interface CustomerCourierWebHookSettingState {
  order: OrderViewModel
  orderStatus: OrderStatusViewModal[]
  orderCourierStatus: OrderStatusViewModal[]
  jsonOrderFromCourier: object
  detailOrderFromCourier: GetOrderFromCourierResponse

  loadingOrderInfo: boolean
  loadingOrderStatus: boolean
  loadingOrderInfoFromCourier: boolean
  loadingOrderWebhookStatus: boolean
}

// 02. Init State
const initState: CustomerCourierWebHookSettingState = {
  order: null,
  orderStatus: null,
  orderCourierStatus: null,
  jsonOrderFromCourier: {},
  detailOrderFromCourier: null,
  
  loadingOrderInfo: false,
  loadingOrderStatus: false,
  loadingOrderInfoFromCourier: false,
  loadingOrderWebhookStatus: false,
}
// 03. slice store
export const orderInfoSlice = createSlice({
  name: 'orderInfo',
  initialState: initState,
  reducers: {
    setOrder: (state, action: PayloadAction<OrderViewModel>) => {
      state.order = action.payload
    },
    setOrderStatus: (state, action: PayloadAction<OrderStatusViewModal[]>) => {
      state.orderStatus = action.payload
    },
    setOrderCourierStatus: (state, action: PayloadAction<OrderStatusViewModal[]>) => {
      state.orderCourierStatus = action.payload
    },
    setJsonOrderFromCourier: (state, action: PayloadAction<object>) => {
      state.jsonOrderFromCourier = action.payload
    },
    setDetailOrderFromCourier: (state, action: PayloadAction<GetOrderFromCourierResponse>) => {
      state.detailOrderFromCourier = action.payload
    },

    


    setLoadingOrderInfo: (state, action: PayloadAction<boolean>) => {
      state.loadingOrderInfo = action.payload
    },
    setLoadingOrderStatus: (state, action: PayloadAction<boolean>) => {
      state.loadingOrderStatus = action.payload
    },
    setLoadingOrderInfoFromCourier: (state, action: PayloadAction<boolean>) => {
      state.loadingOrderInfoFromCourier = action.payload
    },
    setLoadingOrderWebhookStatus: (state, action: PayloadAction<boolean>) => {
      state.loadingOrderWebhookStatus = action.payload
    },

  },
})

//  export actions
export const orderInfoActions = orderInfoSlice.actions

export const selectOrderInfoState = (state: RootState) => state.orderInfo

export default orderInfoSlice.reducer
