import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { DataTablePFSEvent } from 'primereact/datatable';
import {RootState} from '../../../../setup/redux/Store' 

interface AddressType {
  type: string;
  name: string;
}

export interface LazyParamAddress {
  first: number,
  rows: number,
  pageNumber: number,
  totalRecord: number,
  // sortField: null,
  // sortOrder: null,
  // filters: {
  //     'name': { value: '', matchMode: 'contains' },
  //     'country.name': { value: '', matchMode: 'contains' },
  //     'company': { value: '', matchMode: 'contains' },
  //     'representative.name': { value: '', matchMode: 'contains' },
  // }
}

export interface AddressInitState {
  addVisible: boolean,
  updateVisible: boolean,
  switchRefresh: boolean,
  modalAddresId: string,

  filterName: string,
  // filterType: string,
  addressTypeOption: AddressType[],
  addressTypeSelect: AddressType,

  // lazyParam: LazyParamAddress,
  first: number,
  rows: number,
  pageNumber: number,
  totalRecord: number,
}

// 02. Init State
const initState: AddressInitState = {
  addVisible: false,
  updateVisible: false,
  switchRefresh: false,
  modalAddresId: "",

  filterName: "",
  // filterType: "",
  addressTypeOption: [{type: 'S', name: "Địa chỉ gửi"}, {type: 'C', name: "Địa chỉ nhận"}],
  addressTypeSelect: null,

  // lazyParam: {
    first: 0,
    rows: 10,
    pageNumber: 0,
    totalRecord: 100
    // sortField: null,
    // sortOrder: null,
    // filters: {
    //     'name': { value: '', matchMode: 'contains' },
    //     'country.name': { value: '', matchMode: 'contains' },
    //     'company': { value: '', matchMode: 'contains' },
    //     'representative.name': { value: '', matchMode: 'contains' },
    // }
  // }
}
// 03. slice store
export const customerAddressSlice = createSlice({
  name: 'addressInitial',
  initialState: initState,
  reducers: {
    // setAddAddressPopupVisible: (state, action: PayloadAction<boolean>) => {
    //   state.addVisible = action.payload;
    //   if(!!!action.payload) {
    //     state.modalAddresId = "";
    //   };
    // },
    // setUpdateAddressPopupVisible: (state, action: PayloadAction<boolean>) => {
    //   state.updateVisible = action.payload;
    //   if(!!!action.payload) {
    //     state.modalAddresId = "";
    //   };
    // },
    // setModalAddressId: (state, action: PayloadAction<string>) => {
    //   state.modalAddresId = action.payload;
    // },

    // setSwitchRefresh: (state, action: PayloadAction<boolean>) => {
    //   state.switchRefresh = action.payload;
    // },

    refreshListAddress: (state) => {
      state.switchRefresh = !!!state.switchRefresh;
    },

    hideAddPopup: (state) => {
      state.addVisible = false;
      state.modalAddresId = "";
    },
    hideUpdatePopup: (state) => {
      state.updateVisible = false;
      state.modalAddresId = "";
    },
    showAddPopup: (state) => {
      state.addVisible = true;
      state.modalAddresId = "";
    },
    showUpdatePopup: (state, action: PayloadAction<string>) => {
      state.updateVisible = true;
      state.modalAddresId = action.payload;
    },
    
    // setInitPopupVisibleLazy: (state, action: PayloadAction<boolean>) => {
    //   state.loading = action.payload
    // },
    // setInitPopupData: () => {},

    setFilterName: (state, action: PayloadAction<string>) => {
      state.filterName = action.payload;
    },
    // setFilterType: (state, action: PayloadAction<string>) => {
    //   state.filterType = action.payload;
    // },
    setAddressTypeSelect: (state, action: PayloadAction<AddressType>) => {
      state.addressTypeSelect = action.payload;
    },
    setLazyParam: (state, action: PayloadAction<LazyParamAddress>) => {
      // debugger;
      state.first = action.payload.first;
      state.pageNumber = action.payload.pageNumber;
      state.rows = action.payload.rows;
      state.totalRecord = action.payload.totalRecord;
      // console.log(state.lazyParam);
    }
  },
})

//  export actions
export const actions = customerAddressSlice.actions

export const addressBookState = (state: RootState) => state.address

export default customerAddressSlice.reducer
