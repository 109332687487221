import { useFormik } from 'formik'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectState } from './redux/reducer'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { CustomerInitInfoReqModel } from './models'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import { ProgressSpinner } from 'primereact/progressspinner'
import * as Yup from 'yup'
import './CustomerSetInitModal.css'
import locationService from '../../services/locationService'
import { LocationType, LocationViewModel } from '../../models/model/location.model'
import { AutoComplete} from 'primereact/autocomplete'
import { debounce } from 'lodash'
import customerService from '../../services/customerService'
import { toastNotify } from '../../../_metronic/layout/MasterLayout'

interface IEditModel extends CustomerInitInfoReqModel {
  country: Location | undefined | null
  stateProvince: Location | undefined | null
  district: Location | undefined | null
  ward: Location | undefined | null
}

const CustomerInitialModal = () => {
  const state = useSelector(selectState)
  
  const [_loading, _setLoading] = React.useState<boolean>(false)
  const [countries, setCountries] = React.useState<LocationViewModel[]>([])
  const [stateProvinces, setStateProvinces] = React.useState<LocationViewModel[]>([])
  const [districts, setDistricts] = React.useState<LocationViewModel[]>([])
  const [wards, setWards] = React.useState<LocationViewModel[]>([])

  const validationRules = React.useMemo<any>(() => {
    let scm: any = {}

    scm['name'] = Yup.string().required('Vui lòng nhập tên.')
    scm['phoneNumber'] = Yup.string().required('Vui lòng nhập số điện thoại.')
    scm['address1'] = Yup.string().required('Vui lòng nhập địa chỉ')
    scm['country'] = Yup.object().nullable().required('Chọn quốc gia')
    scm['stateProvince'] = Yup.object().nullable().required('Chọn tỉnh thành')
    scm['district'] = Yup.object().nullable().required('Chọn quận huyện')
    scm['ward'] = Yup.object().nullable().required('Chọn phường xã')
    
    return Yup.object().shape(scm)
  }, [])

  const initValues = {}
  const formik = useFormik<any>({
    initialValues: initValues,
    validationSchema: validationRules,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const { country, stateProvince, district, ward, ...editModel } = values
      const modelPost = {
        ...editModel,
        countryId: country?.id,
        countryCode: country?.code,
        country: country?.name,
        stateProvinceId: stateProvince?.id,
        stateProvinceCode: stateProvince?.code,
        stateProvince: stateProvince?.name,
        districtId: district?.id,
        districtCode: district?.code,
        district: district?.name,
        wardId: ward?.id,
        wardCode: ward?.code,
        ward: ward?.name,
      }

      modelPost.countryId = (modelPost.countryId as string).toLowerCase();
      modelPost.stateProvinceId = (modelPost.stateProvinceId as string).toLowerCase();
      modelPost.districtId = (modelPost.districtId as string).toLowerCase();
      modelPost.wardId = (modelPost.wardId as string).toLowerCase();
      
      _setLoading(true)
      customerService
        .postCustomerInitAsync(modelPost)
        .then((res) => {

          const { status, data } = res
          if (data.value) {
            window.location.reload()
            toastNotify?.current?.show({ severity: "success", content: "Đã lưu thông tin người dùng" })
          } else {
            throw new Error();
          }
        })
        .catch((err) => {
          console.error(err);
          toastNotify?.current?.show({ severity: "warn", content: "Có lỗi xảy ra" })
        })
        .finally(() => _setLoading(false))
    },
  })

  const isFormFieldInvalid = (name: string) => !!(formik.touched[name] && formik.errors[name])
  const getFormErrorMessage = (name: string) => {
    return formik.errors[name] && <small className='p-error'>{formik.errors[name]}</small>
  }
  const handleSubmit = () => {
    formik.submitForm();
  }

  // Clear District when  country change
  React.useEffect(() => {
    if (!!!formik.values.country) {
      formik.setValues({
        stateProvince: null,
      })
    }
  }, [formik.values.country])

  React.useEffect(() => {
    if (!!!formik.values.stateProvince) {
      formik.setValues({
        district: null,
      })
    }
  }, [formik.values.stateProvince])
  React.useEffect(() => {
    if (!!!formik.values.district) {
      formik.setValues({
        ward: null,
      })
    }
  }, [formik.values.district])

  const searchLocation = debounce(
    (locationType: LocationType, parentId?: string | null | undefined, q?: string) => {
      locationService.getLocationAsync(locationType, parentId, q).then((res) => {
        if (locationType === LocationType.Country) {
          setCountries(res.data.value || [])
        } else if (locationType === LocationType.StateProvince) {
          setStateProvinces(res.data.value || [])
        } else if (locationType === LocationType.District) {
          setDistricts(res.data.value || [])
        } else if (locationType === LocationType.Ward) {
          setWards(res.data.value || [])
        }
      })
    },
    250
  )

  if (!state.visible) {
    return null
  }
  const dialogFooter = (
    <>
      <Button
        label='Xác nhận'
        icon='pi pi-check'
        type='submit'
        form='frmInitCustomer'
        formAction='submit'
      />
    </>
  )
  return (
    <Dialog
      visible={true}
      onHide={() => { }}
      position='center'
      footer={dialogFooter}
      header={"Khởi tạo tài khoản"}
      closable={false}
      showHeader={true}
      breakpoints={{
        '1400px': '40vw',
        '1200px': '50vw',
        '992px': '60vw',
        '768px': '80vw',
        '576px': '100vw',
      }}
      style={{ width: '45vw' }}
      draggable={false}
      blockScroll={true}
    >
      {(state.loading || _loading) && (
        <div className='progress-spinner'>
          <ProgressSpinner />
        </div>
      )}
      <form
        name='frmInitCustomer'
        id='frmInitCustomer'
        onSubmit={formik.handleSubmit}
        className='p-fluid'
      >
        <div className='formgrid grid'>
          {/* Begin: Name */}
          <div className='field col-6'>
            <label htmlFor='name' className={classNames({ 'p-error': isFormFieldInvalid('name') })}>
              Họ tên(*)
            </label>
            <InputText
              id='name'
              placeholder='Họ và tên'
              className='inputfield w-full'
              autoFocus
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {getFormErrorMessage('name')}
          </div>
          <div className='field col-6'>
            <label
              htmlFor='phoneNumber'
              className={classNames({ 'p-invalid': isFormFieldInvalid('phoneNumber') })}
            >
              Số điện thoại(*)
            </label>
            <InputText
              name='phoneNumber'
              className='w-full'
              keyfilter='num'
              // mask='9999-999-9999'
              placeholder='9999-999-9999'
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
            />
            {getFormErrorMessage('phoneNumber')}
          </div>
          <div className='field col-12'>
            <label
              htmlFor='address1'
              className={classNames({ 'p-invalid': isFormFieldInvalid('address1') })}
            >
              Địa chỉ(*)
            </label>
            <InputText
              name='address1'
              placeholder='Địa chỉ'
              className='w-full'
              value={formik.values.address1}
              onChange={formik.handleChange}
            />
            {getFormErrorMessage('address1')}
          </div>
          <div className='field col-6'>
            <label
              htmlFor='country'
              className={classNames({ 'p-invalid': isFormFieldInvalid('country') })}
            >
              Quốc gia(*)
            </label>
            <AutoComplete
              name='country'
              value={formik.values.country}
              suggestions={countries}
              completeMethod={(e) => searchLocation(LocationType.Country, null, e.query)}
              virtualScrollerOptions={{ itemSize: 38 }}
              field='name'
              dropdown
              placeholder='Quốc gia'
              onChange={formik.handleChange}
              className='inputfield w-full'
            />

            {getFormErrorMessage('country')}
          </div>
          {/* End: CountryId */}
          {/* Begin: StateProvinceId */}
          <div className='field col-6'>
            <label
              htmlFor='stateProvince'
              className={classNames({ 'p-invalid': isFormFieldInvalid('stateProvince') })}
            >
              Tỉnh/Thành phố
            </label>
            <AutoComplete
              name='stateProvince'
              value={formik.values.stateProvince}
              suggestions={stateProvinces}
              completeMethod={(e) =>
                searchLocation(LocationType.StateProvince, formik.values.country.id, e.query)
              }
              virtualScrollerOptions={{ itemSize: 38 }}
              field='name'
              dropdown
              onChange={formik.handleChange}
              className='inputfield w-full'
              placeholder='Tỉnh thành phố'
            />
            {getFormErrorMessage('stateProvince')}
          </div>
          {/* Begin: DistrictId */}
          <div className='field col-6'>
            <label
              htmlFor='district'
              className={classNames({ 'p-invalid': isFormFieldInvalid('district') })}
            >
              Quận/Huyện
            </label>
            <AutoComplete
              name='district'
              value={formik.values.district}
              suggestions={districts}
              completeMethod={(e) => {
                searchLocation(LocationType.District, formik.values.stateProvince?.id, e.query)
              }}
              virtualScrollerOptions={{ itemSize: 38 }}
              field='name'
              dropdown
              onChange={formik.handleChange}
              className='inputfield w-full'
              placeholder='Quận/huyện'
            />
            {getFormErrorMessage('district')}
          </div>
          <div className='field col-6'>
            <label htmlFor='ward' className={classNames({ 'p-invalid': isFormFieldInvalid('ward') })}>
              Xã phường
            </label>
            <AutoComplete
              name='ward'
              value={formik.values.ward}
              suggestions={wards}
              completeMethod={(e) =>
                searchLocation(LocationType.Ward, formik.values.district?.id, e.query)
              }
              virtualScrollerOptions={{ itemSize: 38 }}
              field='name'
              dropdown
              onChange={formik.handleChange}
              className='inputfield w-full'
              placeholder='Xã/phường'
            />
            {getFormErrorMessage('ward')}
          </div>
          {/* Begin: PostCode  */}
          <div className='field col-6'>
            <label
              htmlFor='postCode'
              className={classNames({ 'p-invalid': isFormFieldInvalid('postCode') })}
            >
              Mã bưu chính
            </label>
            <InputText
              name='postCode'
              placeholder='Nhập mã bưu chính'
              value={formik.values.postCode}
              onChange={formik.handleChange}
              className='w-full inputfield'
            />
            {getFormErrorMessage('postCode')}
          </div>
        </div>
      </form>
    </Dialog>
  )
}

export default CustomerInitialModal