import { FC } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { useHistory } from "react-router-dom";
import appSettings from '../../../settings/AppSettings';
import React from 'react';


const Error404Page: FC = () => {
  var history = useHistory();

  React.useEffect(() => {
    document.title = 'Không tìm thấy trang';
  });

  return (
      // <div className='d-flex flex-column flex-root'>
        <div
          // className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain'
          className='card'
          style={{ backgroundColor: 'white' }}
        >
          <div className='d-flex flex-column flex-column-fluid text-center py-15'>
            <a href='/account/dashboard' className='pt-10'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/logo_shipdayroi_light.svg')}
                className='h-70px mb-5'
              />
            </a>
            <div className='pt-10'>
              <h1 className='fw-bolder fs-3x text-gray-700 mb-10'>Page Not Found</h1>

              <div className='fw-bold fs-3 text-gray-400 mb-15'>
                The page you looked not found! <br /> Plan your blog post by choosing a topic
              </div>

              <div className='text-center'>
                <a onClick={() => history.push(appSettings.routes.home)} className='btn btn-lg btn-primary fw-bolder'>
                  Go to homepage
                </a>
              </div>
            </div>
            <div
              className='
                d-flex
                flex-row-auto
                bgi-no-repeat
                bgi-position-x-center
                bgi-size-contain
                bgi-position-y-bottom
                min-h-100px min-h-lg-350px
              '
              style={{
                backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}')`,
              }}
            ></div>
          </div>

        </div>
      // </div>
  )
}

export { Error404Page }
