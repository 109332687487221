import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../../../setup/redux/Store'
import {CustomerCourierWebhookSettingModel} from '../models'

export interface CustomerCourierWebHookSettingState {
  visible: boolean
  loading: boolean
  success: string | undefined
  error: string | undefined
  modelGet?: CustomerCourierWebhookSettingModel
  modelGenerate?: CustomerCourierWebhookSettingModel
}

// 02. Init State
const initState: CustomerCourierWebHookSettingState = {
  visible: false,
  loading: false,
  success: undefined,
  error: undefined,
}
// 03. slice store
export const customerCourierWebHookSettingSlice = createSlice({
  name: 'customerCourierWebhookSetting',
  initialState: initState,
  reducers: {
    MODAL_VISIBLE: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload
    },
    MODAL_LOADING: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    MODAL_ERROR: (state, action: PayloadAction<string>) => {
      state.error = action.payload
    },
    MODAL_SUCCESS: (state, action: PayloadAction<string>) => {
      state.success = action.payload
    },
    RESET_MESSAGE_STATE: (state) => {
      state.success = undefined
      state.error = undefined
    },
    RESET_STATE: (state, actions: any) => {
      state.loading = false
      state.visible = false
      state.error = undefined
      state.success = undefined
      state.modelGet = undefined
      state.modelGenerate = undefined
    },

    MODAL_INIT_SET: (state, action: PayloadAction<CustomerCourierWebhookSettingModel>) => {
      state.modelGet = action.payload
    },
    setModalInitLoadAsync: (
      state,
      action: PayloadAction<{courierId: string; accountId: string}>
    ) => {
      state.visible = true
    },

    generateLinkAsync: (state, action: PayloadAction<{courierId: string; accountId: string}>) => {
      state.loading = true
    },
    GENERATE_LINK_SET: (state, action: PayloadAction<CustomerCourierWebhookSettingModel>) => {
      state.modelGenerate = action.payload
    },

    submitWebhookSettingAsync: (
      state,
      action: PayloadAction<CustomerCourierWebhookSettingModel>
    ) => {
      state.loading = true
    },
  },
})

//  export actions
export const actions = customerCourierWebHookSettingSlice.actions

export const selectState = (state: RootState) => state.customerCourierWebHookSetting

export default customerCourierWebHookSettingSlice.reducer
