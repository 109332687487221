import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../../../setup/redux/Store' 
import {CustomerInitInfoReqModel} from '../models'

export interface CustomerInitState {
  visible: boolean
  loading: boolean
  success: boolean
}

// 02. Init State
const initState: CustomerInitState = {
  visible: false,
  loading: false,
  success: false,
}
// 03. slice store
export const customerInitSlice = createSlice({
  name: 'customerInitial',
  initialState: initState,
  reducers: {
    setInitPopupVisible: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload
    },
    setInitPopupVisibleLazy: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setInitPopupLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    checkInitialCustomerAsync: (state, action) => {},
    postInitCustomerAsync: (state, action: PayloadAction<CustomerInitInfoReqModel>) => {debugger;},
    setInitPopupData: () => {},
    setInitPopupSuccess: (state, action: PayloadAction<CustomerInitInfoReqModel>) => {},
  },
})

//  export actions
export const actions = customerInitSlice.actions

export const selectState = (state: RootState) => state.customerInit

export default customerInitSlice.reducer
