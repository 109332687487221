import jwtDecode from 'jwt-decode'
import {UserManager, UserManagerSettings} from 'oidc-client'
import appSettings from '../../settings/AppSettings'
import {OauthResult} from '../models/responses/base.response'
import {JwtUserInfo} from '../modules/oauth/Types'
// import config, { getCurrentLanguage } from '@shared/config-admin/language.config';

class AuthHelper {
  //   login = async (userInfo) => {
  //     if (!userInfo.username || !userInfo.password) {
  //       return { error: 'please fill in the input' };
  //     }
  //     // return await SuperFetch.post('login', userInfo).then(response => {
  //     //   return this.checkExpirity(response.token);
  //     // });
  //   };
  checkExpirity = (token: string) => {
    if (!token) {
      return {
        error: 'not matched',
      }
    }
    try {
      const profile = jwtDecode<JwtUserInfo>(token)

      const expiredAt = profile.expiredAt || profile.exp * 1000

      if (expiredAt > new Date().getTime()) {
        return {
          ...profile,
          token,
          expiredAt: new Date(expiredAt),
        }
      } else {
        return {error: 'Token expired'}
      }
    } catch (e) {
      console.log(e)

      return {error: 'Server Error'}
    }
  }

  setSession = (authResult: OauthResult) => {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(authResult.expires_at * 1000)
    localStorage.setItem('access_token', authResult.access_token)
    localStorage.setItem('refresh_token', authResult.refresh_token as string)
    localStorage.setItem('id_token', authResult.id_token)
    localStorage.setItem('expires_at', expiresAt)

    const tokenInfo = jwtDecode<JwtUserInfo>(authResult.access_token)
    localStorage.setItem('sub', tokenInfo.sub)
    localStorage.setItem('preferred_username', tokenInfo.preferred_username)
    localStorage.setItem('name', tokenInfo.name);
    localStorage.setItem('email', tokenInfo.email);

    // TODO: Multi language setting
    // localStorage.setItem('languageId', getCurrentLanguage(config.defaultLanguageId).languageId);
    localStorage.setItem('languageId', 'vi')
  }
  clearSession = () => {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('id_token')
    localStorage.removeItem('expires_at')
    localStorage.removeItem('sub')
    localStorage.removeItem('languageId')

    //TO-DO: Clear user  localStorage setting
  }

  isAuthenticated = () => {
    let expiresAt = JSON.parse(localStorage.getItem('expires_at') as string)
    return new Date().getTime() < expiresAt
  }
  getAccessToken = (): string | null => {
    if (!this.isAuthenticated()) {
      this.clearSession()
      return null
    }
    return localStorage.getItem('access_token') as string
  }
  getAuthUserAsync() {
    const userManager = new UserManager(appSettings.sso as UserManagerSettings)
    return userManager.getUser()
  }
  getAuthUserLocalStorage() {
    return {
      user: localStorage.getItem("name"),
      email: localStorage.getItem("email")
    }
  }
  
}
export default new AuthHelper()
