import { toAbsoluteUrl } from '../../../helpers'

export function FallbackView() {
  return (
    <div className='splash-screen'>
      <img
        alt='Logo'
        src={toAbsoluteUrl('/media/logos/logo_shipdayroi_light.svg')}
        className='logo-sticky'
      />
      <span className='fs-6 text-gray-700 text-hover-primary fw-bolder mb-2'>Loading ...</span>
    </div>
  )
}
