import {all} from 'redux-saga/effects'
import * as auth from '../../app/modules/auth'
import customerInitSaga from '../../app/modules/customerInitial/redux/saga'
import courierConnectSaga from '../../app/modules/customerCourierConnect/redux/saga'
import customerCourierWebHookSettingSaga from '../../app/modules/customerCourierWebhook/redux/saga'

export default function* rootSaga() {
  yield all([
    auth.saga(), 
    customerInitSaga(), 
    courierConnectSaga(),
    customerCourierWebHookSettingSaga()
  ])
}
