import {PayloadAction} from '@reduxjs/toolkit'

import {all, call, put, takeLatest} from 'redux-saga/effects'

import {actions} from './reducer'
import customerService from '../../../services/customerService'
import {CustomerInitInfoReqModel} from '../models'
import locationService from '../../../services/locationService'
import {LocationType} from '../../../models/model/location.model'

// type getInitDataSagaResponse = SagaReturnType<typeof  getInitDataAsync>;

function* checkInitialCustomerSaga(action: any) {
  try {
    const {data} = yield call(customerService.getCustomerInfoAsync)
    if (!data.isSuccess) {
      yield put(actions.setInitPopupVisible(true))
      // yield put(actions.setInitPopupLoading(true))
    }
  } catch (e) {
    console.error(e)
    yield put(actions.setInitPopupLoading(false))
    yield put(actions.setInitPopupLoading(false))
  } finally {
    // yield put(actions.setInitPopupLoading(false))
  }
}

function* postInitCustomerSaga(action: PayloadAction<CustomerInitInfoReqModel>) {
  try {
    yield put(actions.setInitPopupLoading(true))
    const {data, status} = yield call(customerService.postCustomerInitAsync, action.payload)

    if (status !== 200) {
      yield put(actions.setInitPopupLoading(false))
      yield put(actions.setInitPopupVisible(false))
    }else{
      yield put(actions.setInitPopupVisible(false))
    }
  } catch (e) {
    console.error(e)
  } finally {
    yield put(actions.setInitPopupLoading(false))
  }
}

// function* setInitPopupDataSaga(action: any) {
//   try {
//     yield put(actions.setInitPopupLoading(true))
//     const {data, status} = yield call(locationService.getLocationAsync, LocationType.Country)
//     if (status !== 200) {
//       yield put(actions.setInitPopupLoading(false))
//     }
//   } catch (e) {
//     console.error(e)
//   } finally {
//     yield put(actions.setInitPopupLoading(false))
//   }
// }




export default function* customerInitSaga() {
  yield all([
    takeLatest(actions.checkInitialCustomerAsync, checkInitialCustomerSaga),
    takeLatest(actions.postInitCustomerAsync, postInitCustomerSaga),
    // takeLatest(actions.setInitPopupData, setInitPopupDataSaga),
  ])
}
