import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../../../setup/redux/Store'
import {LocationCourierForMapping, LocationTplForMapping} from "../../../models/responses/location.response";
import {LocationType} from "../../../models/model/location.model";

export interface ILocationMappingState {
  courierId: string;
  countChild: boolean;
  
  locationTplLoading: boolean;
  locationTpl: LocationTplForMapping[];
  locationTplSelected: LocationTplForMapping[];
  locationTplType: LocationType;
  locationTplProvinceOptions: LocationTplForMapping[];
  locationTplProvinceSelect: LocationTplForMapping;
  locationTplDistrictOptions: LocationTplForMapping[];
  locationTplDistrictSelect: LocationTplForMapping;
  
  
  
  locationCourierLoading: boolean;
  locationCourier: LocationCourierForMapping[];
  locationCourierSelected: LocationCourierForMapping[];
  locationCourierType: LocationType;
  locationCourierProvinceOptions: LocationCourierForMapping[];
  locationCourierProvinceSelect: LocationCourierForMapping;
  locationCourierDistrictOptions: LocationCourierForMapping[];
  locationCourierDistrictSelect: LocationCourierForMapping;
}

const locationMappingStateInit : ILocationMappingState = {
  courierId: 'JTExpress',
  countChild: false,
  
  locationTplLoading: false,
  locationTpl: [],
  locationTplSelected: [],
  locationTplType: LocationType.StateProvince,
  locationTplProvinceOptions: [],
  locationTplProvinceSelect: undefined,
  locationTplDistrictOptions: [],
  locationTplDistrictSelect: undefined,
  
  locationCourierLoading: false,
  locationCourier: [],
  locationCourierSelected: [],
  locationCourierType: LocationType.StateProvince,
  locationCourierProvinceOptions: [],
  locationCourierProvinceSelect: undefined,
  locationCourierDistrictOptions: [],
  locationCourierDistrictSelect: undefined,
}

export const locationMappingSlice = createSlice({
  name: 'locationMapping',
  initialState: locationMappingStateInit,
  reducers: {
    setCourierId: (state, action: PayloadAction<string>) => {
      state.courierId = action.payload;
    },
    toggleCountChild: (state) => {
      state.countChild = !state.countChild;
    },

    setLocationTplLoading: (state, action: PayloadAction<boolean>) => {
      state.locationTplLoading = action.payload;
    },
    setLocationTpl: (state, action: PayloadAction<LocationTplForMapping[]>) => {
      state.locationTpl = action.payload;
    },
    setLocationTplSelected: (state, action: PayloadAction<LocationTplForMapping[]>) => {
      state.locationTplSelected = action.payload;
    },
    clearLocationTplSelected: (state) => {
      state.locationTplSelected = [];
    },
    setLocationTplType: (state, action: PayloadAction<string>) => {
      state.locationTplType = action.payload as LocationType;
    },
    setProvinceTplOptions: (state, action: PayloadAction<LocationTplForMapping[]>) => {
      state.locationTplProvinceOptions = action.payload
    },
    setProvinceTplSelect: (state, action: PayloadAction<LocationTplForMapping>) => {
      state.locationTplProvinceSelect = action.payload;
      state.locationTplDistrictSelect = undefined;
      state.locationTplDistrictOptions = undefined;
    },
    setDistrictTplOptions: (state, action: PayloadAction<LocationTplForMapping[]>) => {
      state.locationTplDistrictOptions = action.payload
    },
    setDistrictTplSelect: (state, action: PayloadAction<LocationTplForMapping>) => {
      state.locationTplDistrictSelect = action.payload
    },

    
    setLocationCourierLoading: (state, action: PayloadAction<boolean>) => {
      state.locationCourierLoading = action.payload;
    },
    setLocationCourier: (state, action: PayloadAction<LocationCourierForMapping[]>) => {
      state.locationCourier = action.payload;
    },
    setLocationCourierSelected: (state, action: PayloadAction<LocationCourierForMapping[]>) => {
      state.locationCourierSelected = action.payload;
    },
    clearLocationCourierSelected: (state) => {
      state.locationCourierSelected = [];
    },
    setLocationCourierType: (state, action: PayloadAction<string>) => {
      state.locationCourierType = action.payload as LocationType;
    },
    setProvinceCourierOptions: (state, action: PayloadAction<LocationCourierForMapping[]>) => {
      state.locationCourierProvinceOptions = action.payload
    },
    setProvinceCourierSelect: (state, action: PayloadAction<LocationCourierForMapping>) => {
      state.locationCourierProvinceSelect = action.payload;
      state.locationCourierDistrictOptions = undefined;
      state.locationCourierDistrictSelect = undefined;
      
    },
    setDistrictCourierOptions: (state, action: PayloadAction<LocationCourierForMapping[]>) => {
      state.locationCourierDistrictOptions = action.payload
    },
    setDistrictCourierSelect: (state, action: PayloadAction<LocationCourierForMapping>) => {
      state.locationCourierDistrictSelect = action.payload
    },
  },
})

//  export actions
export const actions = locationMappingSlice.actions

export const selectorLocationMappingState = (state: RootState) => state.locationMapping

export default locationMappingSlice.reducer
