import {configureStore} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import {reduxBatch} from '@manaflair/redux-batch'
import {persistStore} from 'redux-persist'
import rootReducer from './RootReducer'
import rootSaga from './RootSaga'

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleWare) => [...getDefaultMiddleWare(), sagaMiddleware],
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [reduxBatch],
})
sagaMiddleware.run(rootSaga)
/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store)

export default store

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof rootReducer>
