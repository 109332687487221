export interface MessageModel {
    user: number
    type: 'in' | 'out'
    text: string
    time: string
    template?: boolean
}

const defaultMessages: Array<MessageModel> = [
    {
        user: 4,
        type: 'in',
        text: 'How likely are you to recommend our company to your friends and family ?',
        time: '2 mins',
    },
    {
        user: 2,
        type: 'out',
        text: 'Hey there, we’re just writing to let you know that you’ve been subscribed to a repository on GitHub.',
        time: '5 mins',
    },
    {
        user: 4,
        type: 'in',
        text: 'Ok, Understood!',
        time: '1 Hour',
    },
    {
        user: 2,
        type: 'out',
        text: 'You’ll receive notifications for all issues, pull requests!',
        time: '2 Hours',
    },
    {
        user: 4,
        type: 'in',
        text: 'You can unwatch this repository immediately by clicking here: <a href="https://keenthemes.com">Keenthemes.com</a>',
        time: '3 Hours',
    },
    {
        user: 2,
        type: 'out',
        text: 'Most purchased Business courses during this sale!',
        time: '4 Hours',
    },
    {
        user: 4,
        type: 'in',
        text: 'Company BBQ to celebrate the last quater achievements and goals. Food and drinks provided',
        time: '5 Hours',
    },
    {
        template: true,
        user: 2,
        type: 'out',
        text: '',
        time: 'Just now',
    },
    {
        template: true,
        user: 4,
        type: 'in',
        text: 'Right before vacation season we have the next Big Deal for you.',
        time: 'Just now',
    },
]

export interface UserInfoModel {
    initials?: { label: string; state: 'warning' | 'danger' | 'primary' | 'success' | 'info' }
    name: string
    avatar?: string
    email: string
    position: string
    online: boolean
}

const defaultUserInfos: Array<UserInfoModel> = [
    {
        name: 'Emma Smith',
        avatar: 'avatars/150-1.jpg',
        email: 'e.smith@kpmg.com.au',
        position: 'Art Director',
        online: false,
    },
    {
        name: 'Melody Macy',
        initials: {label: 'M', state: 'danger'},
        email: 'melody@altbox.com',
        position: 'Marketing Analytic',
        online: true,
    },
    {
        name: 'Max Smith',
        avatar: 'avatars/150-2.jpg',
        email: 'max@kt.com',
        position: 'Software Enginer',
        online: false,
    },
    {
        name: 'Sean Bean',
        avatar: 'avatars/150-4.jpg',
        email: 'sean@dellito.com',
        position: 'Web Developer',
        online: false,
    },
    {
        name: 'Brian Cox',
        avatar: 'avatars/150-15.jpg',
        email: 'brian@exchange.com',
        position: 'UI/UX Designer',
        online: false,
    },
    {
        name: 'Mikaela Collins',
        initials: {label: 'M', state: 'warning'},
        email: 'mikaela@pexcom.com',
        position: 'Head Of Marketing',
        online: true,
    },
    {
        name: 'Francis Mitcham',
        avatar: 'avatars/150-8.jpg',
        email: 'f.mitcham@kpmg.com.au',
        position: 'Software Arcitect',
        online: false,
    },

    {
        name: 'Olivia Wild',
        initials: {label: 'O', state: 'danger'},
        email: 'olivia@corpmail.com',
        position: 'System Admin',
        online: true,
    },
    {
        name: 'Neil Owen',
        initials: {label: 'N', state: 'primary'},
        email: 'owen.neil@gmail.com',
        position: 'Account Manager',
        online: true,
    },
    {
        name: 'Dan Wilson',
        avatar: 'avatars/150-6.jpg',
        email: 'dam@consilting.com',
        position: 'Web Desinger',
        online: false,
    },
    {
        name: 'Emma Bold',
        initials: {label: 'E', state: 'danger'},
        email: 'emma@intenso.com',
        position: 'Corporate Finance',
        online: true,
    },
    {
        name: 'Ana Crown',
        avatar: 'avatars/150-7.jpg',
        email: 'ana.cf@limtel.com',
        position: 'Customer Relationship',
        online: false,
    },
    {
        name: 'Robert Doe',
        initials: {label: 'A', state: 'info'},
        email: 'robert@benko.com',
        position: 'Marketing Executive',
        online: true,
    },
    {
        name: 'John Miller',
        avatar: 'avatars/150-17.jpg',
        email: 'miller@mapple.com',
        position: 'Project Manager',
        online: false,
    },
    {
        name: 'Lucy Kunic',
        initials: {label: 'L', state: 'success'},
        email: 'lucy.m@fentech.com',
        position: 'SEO Master',
        online: true,
    },
    {
        name: 'Ethan Wilder',
        avatar: 'avatars/150-10.jpg',
        email: 'ethan@loop.com.au',
        position: 'Accountant',
        online: true,
    },
]

const messageFromClient: MessageModel = {
    user: 4,
    type: 'in',
    text: 'Thank you for your awesome support!',
    time: 'Just now',
}

export interface AlertModel {
    title: string
    description: string
    time: string
    icon: string
    state: 'primary' | 'danger' | 'warning' | 'success' | 'info'
}

const defaultAlerts: Array<AlertModel> = [
    {
        title: 'Project Alice',
        description: 'Phase 1 development',
        time: '1 hr',
        icon: 'icons/duotune/technology/teh008.svg',
        state: 'primary',
    },
    {
        title: 'HR Confidential',
        description: 'Confidential staff documents',
        time: '2 hrs',
        icon: 'icons/duotune/general/gen044.svg',
        state: 'danger',
    },
    {
        title: 'Company HR',
        description: 'Corporeate staff profiles',
        time: '5 hrs',
        icon: 'icons/duotune/finance/fin006.svg',
        state: 'warning',
    },
    {
        title: 'Project Redux',
        description: 'New frontend admin theme',
        time: '2 days',
        icon: 'icons/duotune/files/fil023.svg',
        state: 'success',
    },
    {
        title: 'Project Breafing',
        description: 'Product launch status update',
        time: '21 Jan',
        icon: 'icons/duotune/maps/map001.svg',
        state: 'primary',
    },
    {
        title: 'Banner Assets',
        description: 'Collection of banner images',
        time: '21 Jan',
        icon: 'icons/duotune/general/gen006.svg',
        state: 'info',
    },
    {
        title: 'Icon Assets',
        description: 'Collection of SVG icons',
        time: '20 March',
        icon: 'icons/duotune/art/art002.svg',
        state: 'warning',
    },
]

export interface LogModel {
    code: string
    state: 'success' | 'danger' | 'warning'
    message: string
    time: string
}

const defaultLogs: Array<LogModel> = [
    {code: '200 OK', state: 'success', message: 'New order', time: 'Just now'},
    {code: '500 ERR', state: 'danger', message: 'New customer', time: '2 hrs'},
    {code: '200 OK', state: 'success', message: 'Payment process', time: '5 hrs'},
    {code: '300 WRN', state: 'warning', message: 'Search query', time: '2 days'},
    {code: '200 OK', state: 'success', message: 'API connection', time: '1 week'},
    {code: '200 OK', state: 'success', message: 'Database restore', time: 'Mar 5'},
    {code: '300 WRN', state: 'warning', message: 'System update', time: 'May 15'},
    {code: '300 WRN', state: 'warning', message: 'Server OS update', time: 'Apr 3'},
    {code: '300 WRN', state: 'warning', message: 'API rollback', time: 'Jun 30'},
    {code: '500 ERR', state: 'danger', message: 'Refund process', time: 'Jul 10'},
    {code: '500 ERR', state: 'danger', message: 'Withdrawal process', time: 'Sep 10'},
    {code: '500 ERR', state: 'danger', message: 'Mail tasks', time: 'Dec 10'},
]


const employees = [{
    "EmployeeID": 1,
    "FullName": "Nancy Davolio",
    "Position": "Sales Representative",
    "TitleOfCourtesy": "Ms.",
    "BirthDate": "1968-12-08T00:00:00.000Z",
    "HireDate": "2011-05-01T00:00:00.000Z",
    "Address": "507 - 20th Ave. E.\r\nApt. 2A",
    "City": "Seattle",
    "Region": "WA",
    "PostalCode": "98122",
    "Country": "USA",
    "HomePhone": "(206) 555-9857",
    "Extension": "5467",
    "Photo": "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png",
    "Notes": "Education includes a BA in psychology from Colorado State University in 1990.  She also completed \"The Art of the Cold Call.\"  Nancy is a member of Toastmasters International.",
    "ReportsTo": 2
}, {
    "EmployeeID": 2,
    "FullName": "Andrew Fuller",
    "Position": "Vice President, Sales",
    "TitleOfCourtesy": "Dr.",
    "BirthDate": "1972-02-19T00:00:00.000Z",
    "HireDate": "2011-08-14T00:00:00.000Z",
    "Address": "908 W. Capital Way",
    "City": "Tacoma",
    "Region": "WA",
    "PostalCode": "98401",
    "Country": "USA",
    "HomePhone": "(206) 555-9482",
    "Extension": "3457",
    "Photo": "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/02.png",
    "Notes": "Andrew received his BTS commercial in 1994 and a Ph.D. in international marketing from the University of Dallas in 2001.  He is fluent in French and Italian and reads German.  He joined the company as a sales representative, was promoted to sales manager in January 2012 and to vice president of sales in March 2013.  Andrew is a member of the Sales Management Roundtable, the Seattle Chamber of Commerce, and the Pacific Rim Importers Association.",
    "ReportsTo": null
}, {
    "EmployeeID": 3,
    "FullName": "Janet Leverling",
    "Position": "Sales Representative",
    "TitleOfCourtesy": "Ms.",
    "BirthDate": "1983-08-30T00:00:00.000Z",
    "HireDate": "2011-04-01T00:00:00.000Z",
    "Address": "722 Moss Bay Blvd.",
    "City": "Kirkland",
    "Region": "WA",
    "PostalCode": "98033",
    "Country": "USA",
    "HomePhone": "(206) 555-3412",
    "Extension": "3355",
    "Photo": "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/09.png",
    "Notes": "Janet has a BS degree in chemistry from Boston College (2004).  She has also completed a certificate program in food retailing management.  Janet was hired as a sales associate in 2011 and promoted to sales representative in February 2012.",
    "ReportsTo": 2
}, {
    "EmployeeID": 4,
    "FullName": "Margaret Peacock",
    "Position": "Sales Representative",
    "TitleOfCourtesy": "Mrs.",
    "BirthDate": "1957-09-19T00:00:00.000Z",
    "HireDate": "2012-05-03T00:00:00.000Z",
    "Address": "4110 Old Redmond Rd.",
    "City": "Redmond",
    "Region": "WA",
    "PostalCode": "98052",
    "Country": "USA",
    "HomePhone": "(206) 555-8122",
    "Extension": "5176",
    "Photo": "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/04.png",
    "Notes": "Margaret holds a BA in English literature from Concordia College (1978) and an MA from the American Institute of Culinary Arts (1986).  She was assigned to the London office temporarily from July through November 2012.",
    "ReportsTo": 2
}, {
    "EmployeeID": 5,
    "FullName": "Steven Buchanan",
    "Position": "Sales Manager",
    "TitleOfCourtesy": "Mr.",
    "BirthDate": "1975-03-04T00:00:00.000Z",
    "HireDate": "2012-10-17T00:00:00.000Z",
    "Address": "14 Garrett Hill",
    "City": "London",
    "Region": "UK",
    "PostalCode": "SW1 8JR",
    "Country": "UK",
    "HomePhone": "(71) 555-4848",
    "Extension": "3453",
    "Photo": "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/05.png",
    "Notes": "Steven Buchanan graduated from St. Andrews University, Scotland, with a BSC degree in 1996.  Upon joining the company as a sales representative in 2012, he spent 6 months in an orientation program at the Seattle office and then returned to his permanent post in London.  He was promoted to sales manager in March 2013.  Mr. Buchanan has completed the courses \"Successful Telemarketing\" and \"International Sales Management.\"  He is fluent in French.",
    "ReportsTo": 2
}, {
    "EmployeeID": 6,
    "FullName": "Michael Suyama",
    "Position": "Sales Representative",
    "TitleOfCourtesy": "Mr.",
    "BirthDate": "1983-07-02T00:00:00.000Z",
    "HireDate": "2012-10-17T00:00:00.000Z",
    "Address": "Coventry House\r\nMiner Rd.",
    "City": "London",
    "Region": "UK",
    "PostalCode": "EC2 7JR",
    "Country": "UK",
    "HomePhone": "(71) 555-7773",
    "Extension": "428",
    "Photo": "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/01.png",
    "Notes": "Michael is a graduate of Sussex University (MA, economics, 2003) and the University of California at Los Angeles (MBA, marketing, 2006). He has also taken the courses \"Multi-Cultural Selling\" and \"Time Management for the Sales Professional.\"  He is fluent in Japanese and can read and write French, Portuguese, and Spanish.",
    "ReportsTo": 5
}, {
    "EmployeeID": 7,
    "FullName": "Robert King",
    "Position": "Sales Representative",
    "TitleOfCourtesy": "Mr.",
    "BirthDate": "1980-05-29T00:00:00.000Z",
    "HireDate": "2012-01-02T00:00:00.000Z",
    "Address": "Edgeham Hollow\r\nWinchester Way",
    "City": "London",
    "Region": "UK",
    "PostalCode": "RG1 9SP",
    "Country": "UK",
    "HomePhone": "(71) 555-5598",
    "Extension": "465",
    "Photo": "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/07.png",
    "Notes": "Robert King served in the Peace Corps and traveled extensively before completing his degree in English at the University of Michigan in 2002, the year he joined the company.  After completing a course entitled \"Selling in Europe,\" he was transferred to the London office in March 2013.",
    "ReportsTo": 5
}, {
    "EmployeeID": 8,
    "FullName": "Laura Callahan",
    "Position": "Inside Sales Coordinator",
    "TitleOfCourtesy": "Ms.",
    "BirthDate": "1978-01-09T00:00:00.000Z",
    "HireDate": "2012-03-05T00:00:00.000Z",
    "Address": "4726 - 11th Ave. N.E.",
    "City": "Seattle",
    "Region": "WA",
    "PostalCode": "98105",
    "Country": "USA",
    "HomePhone": "(206) 555-1189",
    "Extension": "2344",
    "Photo": "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/08.png",
    "Notes": "Laura received a BA in psychology from the University of Washington.  She has also completed a course in business French.  She reads and writes French.",
    "ReportsTo": 2
}, {
    "EmployeeID": 9,
    "FullName": "Brett Wade",
    "Position": "Sales Representative",
    "TitleOfCourtesy": "Mr.",
    "BirthDate": "1986-01-27T00:00:00.000Z",
    "HireDate": "2012-11-15T00:00:00.000Z",
    "Address": "7 Houndstooth Rd.",
    "City": "London",
    "Region": "UK",
    "PostalCode": "WG2 7LT",
    "Country": "UK",
    "HomePhone": "(71) 555-4444",
    "Extension": "452",
    "Photo": "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/03.png",
    "Notes": "Brett has a BA degree in English from St. Lawrence College.  He is fluent in French and German.",
    "ReportsTo": 5
}];

export {defaultMessages, defaultUserInfos, defaultAlerts, defaultLogs, messageFromClient, employees}
