import React, { useEffect } from 'react'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { Toolbar } from './components/toolbar/Toolbar'
import { Content } from './components/Content'
import { PageDataProvider, useLayout } from './core'
import { useLocation } from 'react-router-dom'
import { MenuComponent } from '../assets/ts/components'
import CustomerInitialModal from '../../app/modules/customerInitial/CustomerSetInitModal'
import { Toast } from 'primereact/toast'

let toastNotify: React.MutableRefObject<Toast> = null

const MasterLayout: React.FC = ({ children }) => {
  const { classes } = useLayout()
  const toast = React.useRef<Toast>()

  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  React.useEffect(() => {
    toastNotify = toast
  }, [toast])

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div
              className='d-flex flex-column-fluid align-items-start container-fluid fs-6 text-gray-700 fw-bolder'
              id='kt_post'
            >
              <Content>{children}</Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <CustomerInitialModal />
      <Toast ref={toast} ></Toast>
    </PageDataProvider>
  )
}

export { MasterLayout, toastNotify }
