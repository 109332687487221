import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import { UserManager, UserManagerSettings } from 'oidc-client'
import authHelper from '../../app/utils/authHelper'
import appSettings from '../../settings/AppSettings'

export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken},
      } = store.getState()
      let accToken = accessToken
      if (!accToken) {
        accToken = authHelper.getAccessToken()
      }

      if (accToken) {
        config.headers.Authorization = `Bearer ${accToken}`
      }

      var date = new Date()
      config.headers.TPL_CLIENT_OFFSET = date.getTimezoneOffset().toFixed(0)
      config.headers.TPL_LANG_CD = 'VI'

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

const appAxios = axios.create({
  baseURL: appSettings.baseApiUrl,
  //baseURL: 'http://10.110.1.210:5000/api',
  //baseURL: 'http://localhost:50000',
  timeout: 180000,
  paramsSerializer: (params) =>
    Object.keys(params)
      .filter((key) => params[key] !== null && params[key] !== undefined)
      .map((key) => `${key}=${encodeURI(params[key])}`)
      .join('&'),
})
appAxios.interceptors.response.use(
  function (response) {return response;},
  function (error) {
    if (error.response.status === 401) {
      authHelper.clearSession();
      const ssoConfig = appSettings.sso as UserManagerSettings;
      var userMng = new UserManager(ssoConfig);
      userMng.signoutRedirect();
    }
    return error;
  })

const apiService = {
  get: async <T>(url: string, config?: AxiosRequestConfig) => {
    try {
      const result = await appAxios.get<AxiosResponse<T>>(url, config)
      return result.data
    } catch (e) {
      throw new Error(e)
    }
  },

  post: async <T>(url: string, data?: any, config?: AxiosRequestConfig) => {
    try {
      const result = await appAxios.post<AxiosResponse<T>>(url, data, config)
      return result.data
    } catch (e) {
      throw new Error(e)
    }
  },
  put: async <T>(url: string, config?: AxiosRequestConfig) => {
    try {
      const result = await appAxios.put<AxiosResponse<T>>(url, config)
      return result.data
    } catch (e) {
      throw new Error(e)
    }
  },
  delete: async <T>(url: string, config?: AxiosRequestConfig) => {
    try {
      const result = await appAxios.delete<AxiosResponse<T>>(url, config)
      return result.data
    } catch (e) {
      throw new Error(e)
    }
  },
  patch: async <T>(url: string, config?: AxiosRequestConfig) => {
    try {
      const result = await appAxios.patch<AxiosResponse<T>>(url, config)
      return result.data
    } catch (e) {
      throw new Error(e)
    }
  },
}
export {appAxios, apiService}
