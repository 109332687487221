import { combineReducers } from 'redux'
import * as auth from '../../app/modules/auth'
import { customerInitSlice } from '../../app/modules/customerInitial/redux/reducer'
import { courierConnectSlice } from '../../app/modules/customerCourierConnect/redux/reducer'
import { customerCourierWebHookSettingSlice } from '../../app/modules/customerCourierWebhook/redux/reducer'
import {customerAddressSlice} from '../../app/modules/customerAddressBook/redux/reducer'
import { courierSlice } from '../../app/modules/customerCourier/redux/reducer'
import { orderInfoSlice } from '../../app/modules/orderInfo/redux/reducer'
import {locationMappingSlice} from "../../app/modules/locationMapping/redux/reducer";

const rootReducer = combineReducers({
  auth: auth.reducer,
  customerInit: customerInitSlice.reducer,
  courierState: courierSlice.reducer,
  courierConnect: courierConnectSlice.reducer,
  customerCourierWebHookSetting: customerCourierWebHookSettingSlice.reducer,
  orderInfo: orderInfoSlice.reducer,
  address: customerAddressSlice.reducer,
  locationMapping: locationMappingSlice.reducer,
})

export default rootReducer
