
export default {
  formatMoney(money: number) {
      if(!money) return "";  

      // copy https://stackoverflow.com/a/14428340/9316058
      // return money?.toFixed(0).replace(/\d(?=(\d{3})+\.)/g, '$&,');

      var formatter = new Intl.NumberFormat(
        'en-US', 
        {
        // style: 'currency',
        // currency: 'none',
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        }
      );
      
      return formatter.format(money);
    },

  objectToQueryUrl(obj: Object) {
    const queryUrl = Object.entries(obj).filter(([key, value]) => !!value).map(([key, value]) => `${key}=${value}&`).reduce((prev, current) => prev.concat(current), "");
    return `?${queryUrl}xoa-la-loi=0`;
  }
};