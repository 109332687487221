import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { stat } from "fs";
import { RootState } from "../../../../setup/redux/Store";
import { CourierViewModel } from "../../../models/model/courier.model";
import CustomerCourierViewModel from "../../../models/model/CourierCustomerViewModel";
import { LocationViewModel } from "../../../models/model/location.model";


export interface CourierModalState {
    visible: boolean
    loading: boolean
    courier: CustomerCourierViewModel
}

const initCourierModalState: CourierModalState = {
    visible: false,
    loading: false,
    courier: null
}

export interface IStatusFilter {
    isActive: boolean
    name: string
}

export interface CourierPageState {
    courierModalState: CourierModalState,
    courierTypes: CourierViewModel[],
    courierTypeSelected: CourierViewModel | null,
    countryFilters: LocationViewModel[], 
    countryFilterSeleted: LocationViewModel, 
    couriers: CustomerCourierViewModel[],
    statusFilter: boolean,
    statusFilterOption: IStatusFilter[],
    isGroupByCourier: boolean,
}

const initCourierState: CourierPageState = {
    courierModalState: {... initCourierModalState},
    courierTypes: [],
    courierTypeSelected: null,
    countryFilters: [], 
    countryFilterSeleted: null, 
    couriers: [],
    statusFilter: true,
    statusFilterOption: [{isActive: true, name: "Đang kích hoạt"}, {isActive: false, name: "Chưa kích hoạt"}],
    isGroupByCourier: false,
}

export const courierSlice = createSlice({
    name: "courier",
    initialState: initCourierState,
    reducers: {

        // modal pop up
        setCourierModalVisible: (state, action: PayloadAction<boolean>) => {
            state.courierModalState.visible = action.payload;
        },
        setCourierModalLoading: (state, action: PayloadAction<boolean>) => {
            state.courierModalState.loading = action.payload;
        },

        setCourierModalViewModal: (state, action: PayloadAction<CustomerCourierViewModel>) => {
            state.courierModalState.courier = action.payload;
        },

        // filter
        setCourierTypes: (state, action: PayloadAction<CourierViewModel[]>) => {
            state.courierTypes = action.payload;
        },
        setCourierTypeSelected: (state, action: PayloadAction<CourierViewModel>) => {
            state.courierTypeSelected = action.payload;
        },
        setCountryFilters: (state, action: PayloadAction<LocationViewModel[]>) => {
            state.countryFilters = action.payload;
        },
        setCountryFilterSeleted: (state, action: PayloadAction<LocationViewModel>) => {
            state.countryFilterSeleted = action.payload;
        },
        setStatusFilter: (state, action: PayloadAction<boolean>) => {
            state.statusFilter = action.payload;
        },
        toggleGroupByCourier: (state, action: PayloadAction) => {
            state.isGroupByCourier = !state.isGroupByCourier;
        },

        // main page state
        setCouriers: (state, action: PayloadAction<CustomerCourierViewModel[]>) => {
            state.couriers = action.payload;
        }


    }
})

export const actions = courierSlice.actions

export const selectCourierState = (state: RootState) => state.courierState
export const selectCourierMocalState = (state: RootState) => state.courierState.courierModalState

export default courierSlice.reducer;