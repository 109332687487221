/* eslint-disable import/no-anonymous-default-export */

import appSettings from '../../settings/AppSettings'
import {apiService, appAxios} from '../../setup/axios/SetupAxios'
import {BaseEntityResponse, BasePagedEntityResponse, BaseResponse} from '../models/model'
import CustomerCourierViewModel, {
  PriceListViewModel,
} from '../models/model/CourierCustomerViewModel'
import {CustomerAddressViewModel} from '../models/model/customer-address.model'
import {
  AddCustomerAddressRequest,
  GetCustomerAddressQuery,
  UpdateCustomerAddressRequest,
} from '../models/requests/customer-address.request'
import {
  GetPriceListCourierRequest,
} from '../models/requests/customer-courier.request'
import { CustomerUpdateCommand } from '../models/requests/customer.request'
import {BaseEntityResult, BaseResult} from '../models/responses/base.response'
import { CustomerInfoDto } from '../models/responses/customer.response'
import {CloneCourierModel} from '../models/model/CloneCourierModel'
import {CloneDefaultCourierModel} from '../models/model/CloneDefaultCourierModel'
import {CustomerCourierWebhookSettingModel} from '../modules/customerCourierWebhook/models'
import {CustomerInitInfoReqModel} from '../modules/customerInitial/models'
import Formater from '../utils/formater'

const api = appSettings.api.customer

export default {
  // customer
  getCustomerInfoAsync: async () => {
    return await appAxios.get<BaseEntityResult<CustomerInfoDto>>(api.index)
  },
  postCustomerInitAsync: async (model: CustomerInitInfoReqModel) => {
    debugger;
    return await appAxios.post<BaseEntityResult<boolean>>(api.index, model)
  },
  updateCustomerAsync: (model: CustomerUpdateCommand) => {
    return appAxios.put<BaseResponse>(api.index, model);
  },
  
    // courier
  getCustomerCourierSettingAsync: async (courierId: string, accountId: string) => {
    return await appAxios.get<BaseEntityResult<any>>(
      api.courierIdAccountId.replace(':courierId', courierId).replace(':accountId', accountId)
    )
  },
  saveCustomerCourierSettingAsync: async (courierId: string, accountId: string, model: any) => {
    if(!courierId || !accountId || courierId === '' || accountId === '') {
      debugger;
      throw new Error("courier and accountId must not empty");
    }
    return await appAxios.post<BaseEntityResult<boolean>>(
      api.courierIdAccountId.replace(':courierId', courierId).replace(':accountId', accountId),
      model
    )
  },
  removeCourierConnectAsync: async (courierId: string, accountId: string) => {
    const endpoint = api.courierIdAccountId
      .replace(':courierId', courierId)
      .replace(':accountId', accountId)
    return await appAxios.delete<BaseResponse>(endpoint)
  },
  setActiveCourierConnectAsync: async (courierId: string, accountId: string, active: boolean) => {
    const endpoint = active ? api.courierIdAccountIdActive : api.courierIdAccountIdDeactive
    return await appAxios.post<BaseResponse>(
      endpoint.replace(':courierId', courierId).replace(':accountId', accountId)
    )
  },
  setDefaultCourierAsync: async (courierId: string, accountId: string) => {
    const endpoint = api.setDefaultCourierAccount
    return await appAxios.post<BaseResponse>(endpoint, {
      CourierId: courierId,
      AccountId: accountId,
    })
  },
  getCustomerCouriersAsync: async () => {
    return await appAxios.get<BaseResult<CustomerCourierViewModel[]>>(api.couriers)
  },

  getCustomerCouriersConnectedAsync: async () => {
    return await appAxios.get<BaseResult<CustomerCourierViewModel[]>>(api.couriersConnected)
  },

  // address book
  getAddressBookAsync: async (request: GetCustomerAddressQuery) => {
    return await appAxios.get<BasePagedEntityResponse<CustomerAddressViewModel>>(
      // `${api.addressBook}?pageNumber=${request.pageNumber}&pageSize=${request.pageSize}`
      `${api.addressBook}${Formater.objectToQueryUrl(request)}`
    )
  },
  getAddressBookByIdAsync: async (addressId: string) => {
    let res = await appAxios.get<BaseEntityResponse<CustomerAddressViewModel>>(
      api.addressBookId.replace(':addressId', addressId)
    )
    return res
  },
  addAddressBookAsync: async (request: AddCustomerAddressRequest) => {
    return await appAxios.post<BaseResponse>(api.addressBook, request)
  },
  deleteAddressBookAsync: async (id: string) => {
    return await appAxios.delete<BaseResponse>(api.addressBookId.replace(':addressId', id))
  },
  updateAddressBookAsync: async (id: string, request: UpdateCustomerAddressRequest) => {
    return await appAxios.put<BaseEntityResponse<UpdateCustomerAddressRequest>>(
      api.addressBookId.replace(':addressId', id),
      request
    )
  },
  getCourierPriceList: async (
    request: GetPriceListCourierRequest
  ): Promise<BaseResult<PriceListViewModel[]>> => {
    return await appAxios
      .post<BaseResult<PriceListViewModel[]>>(api.getPriceListCourier, request)
      .then((res) => {
        return res.data
      })
  },
  // searchAddress: async (
  //   request: string | null
  // ): Promise<BaseEntityResponse<CustomerAddressViewModel[]>> => {
  //   return await appAxios
  //     .get<BaseEntityResponse<CustomerAddressViewModel[]>>(
  //       request === null ? `${api.searchAddress}?keyword=` : `${api.searchAddress}?keyword=${request}`
  //     )
  //     .then((res) => {
  //       return res.data
  //     })
  // },

  //#region    WebHook Config
  generateWebhookSettingAsync: async ({
    courierId,
    accountId,
  }: {
    courierId: string
    accountId: string
  }) => {
    return await apiService.get<BaseEntityResponse<CustomerCourierWebhookSettingModel>>(
      api.generateWebhookLink.replace(':courierId', courierId).replace(':accountId', accountId)
    )
  },
  getCustomerCourierWebhookSettingAsync: async ({
    courierId,
    accountId,
  }: {
    courierId: string
    accountId: string
  }) => {
    return await apiService.get<BaseEntityResponse<CustomerCourierWebhookSettingModel>>(
      api.webhookLink.replace(':courierId', courierId).replace(':accountId', accountId)
    )
  },
  submitWebhookSettingAsync: async (model: CustomerCourierWebhookSettingModel) => {
    return await apiService.post<BaseResponse>(
      api.webhookLink.replace(':courierId', model.courierId).replace(':accountId', model.accountId),
      model
    )
  },
  //#endregion

  cloneAccount: async (model: CloneCourierModel) => {
    return await appAxios.post<BaseResult<boolean>>(api.cloneCourier, model)
  },

  cloneDefaultAccount: async (model: CloneDefaultCourierModel) => {
    return await appAxios.post<BaseResult<boolean>>(api.cloneDefaultCourier, model)
  },
}
