/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, lazy } from 'react'
import { Switch, Route } from 'react-router-dom'
import { PrivateRoutes, PrivRoute } from './PrivateRoutes'
// import {ErrorsPage} from '../modules/errors/ErrorsPage'

import appSettings from '../../settings/AppSettings'

const routes = appSettings.routes

const publicRoutes = [
  {
    path: routes.publicRoutes.errors,
    component: lazy(() =>
      import('./../modules/errors/ErrorsPage').then((module) => ({ default: module.ErrorsPage }))
    ),
  },
  {
    path: routes.publicRoutes.signIn,
    //exact: true,
    component: lazy(() => import('./../modules/oauth/Signin')),
  },
  {
    path: routes.publicRoutes.redirectUri,
    // exact: true,
    component: lazy(() => import('./../modules/oauth/Callback')),
  },
  {
    path: routes.publicRoutes.silentRedirectUri,
    // exact: true,
    component: lazy(() => import('../modules/oauth/Silent')),
  },
]

const Routes: FC = () => {
  return (
    <>
      <Switch>
        {publicRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            exact={true}
            component={route.component}
          />
        ))}
        <PrivRoute path='/'>
          <PrivateRoutes />
        </PrivRoute>
      </Switch>
    </>
  )
}

export { Routes }
