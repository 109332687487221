import React, {FC} from 'react'
import {useLang} from './Metronici18n'
import {createIntl, createIntlCache, IntlProvider, IntlShape, RawIntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'

import deMessages from './messages/de.json'
import enMessages from './messages/en.json'
import esMessages from './messages/es.json'
import frMessages from './messages/fr.json'
import jaMessages from './messages/ja.json'
import zhMessages from './messages/zh.json'
import viMessages from './messages/vi.json'

const allMessages = {
  de: deMessages,
  en: enMessages,
  es: esMessages,
  fr: frMessages,
  ja: jaMessages,
  zh: zhMessages,
  vi: viMessages,
}

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache()
let INTL: IntlShape

const I18nProvider: FC = ({children}) => {
  const locale = useLang()
  // const messages = allMessages[locale]
  INTL = createIntl(
    {
      locale: locale,
      messages: allMessages[locale],
    },
    cache
  )

  return (
    // <IntlProvider locale={locale} messages={messages}  >
    <RawIntlProvider value={INTL}>{children}</RawIntlProvider>
  )
}

export {I18nProvider, INTL}
