const getEnvVariable: any = () => {
  return process.env
}

const {
  REACT_APP_API_URL,
  REACT_APP_BASE_URL,
  REACT_APP_SSO_TYPE,
  REACT_APP_SSO_AUTHORITY,
  REACT_APP_SSO_CLIENT_ID,
  REACT_APP_SSO_CLIENT_SECRET,
  REACT_APP_SSO_REDIRECT_URI,
  REACT_APP_SSO_POST_LOGOUT_REDIRECT_URI,
  REACT_APP_SSO_RESPONSE_TYPE,
  REACT_APP_SSO_SCOPE,
  REACT_APP_SSO_LOAD_USER_INFO,
  REACT_APP_SSO_SILENT_REDIRECT_URI,
  REACT_APP_SSO_AUTOMATIC_SILENT_RENEW,
  REACT_APP_SSO_REVOKE_ACCESS_TOKEN_ON_SIGNOUT,
} = getEnvVariable()

const appSettings = {
  ssoType: REACT_APP_SSO_TYPE,
  baseApiUrl: REACT_APP_API_URL,
  sso: {
    authority: REACT_APP_SSO_AUTHORITY,
    client_id: REACT_APP_SSO_CLIENT_ID,
    client_secret: REACT_APP_SSO_CLIENT_SECRET,
    redirect_uri: REACT_APP_SSO_REDIRECT_URI,
    post_logout_redirect_uri: REACT_APP_SSO_POST_LOGOUT_REDIRECT_URI,
    //response_type: "id_token token",
    response_type: REACT_APP_SSO_RESPONSE_TYPE,
    scope: REACT_APP_SSO_SCOPE,
    loadUserInfo: REACT_APP_SSO_LOAD_USER_INFO,
    silent_redirect_uri: REACT_APP_SSO_SILENT_REDIRECT_URI,
    automaticSilentRenew: REACT_APP_SSO_AUTOMATIC_SILENT_RENEW,
    //monitorAnonymousSession: true,
    revokeAccessTokenOnSignout: REACT_APP_SSO_REVOKE_ACCESS_TOKEN_ON_SIGNOUT,
    //filterProtocolClaims: false
  },
  routes: {
    publicRoutes: {
      landing: '/',
      signIn: '/signin',
      signOut: '/signout',
      signUp: '/signup',
      forgetPassword: '/forgotpassword',
      resetPassword: '/resetpassword',
      errors: '/errors',
      page404: '/errors/404',
      page500: '/errors/500',
      redirectUri: '/callback',
      postLogoutRedirectUri: '/',
      silentRedirectUri: '/silent',
    },
    home: '/',
    dashboard: {
      index: '/account/dashboard',
    },
    order: {
      index: '/account/orders',
      list: '/account/orders/list',
      create: '/account/orders/create',
      edit: '/account/order/:id',
      info: '/account/order-info/:id',
    },
    orderShipping: {
      index: '/account/orders-shipping',
      new: '/account/orders-shipping/new',
      edit: '/account/orders-shipping/:id',
    },
    courier: {
      index: `/account/couriers`,
      setup: `/account/courier-connect/:courierId/:accountId`,
      connect: `/account/courier-connect`,
    },
    addressBook: {
      index: '/account/address-book',
      create: '/account/address-book/add',
      edit: '/account/address-book/edit/:id',
    },
    profile: {
      index: '/account/profile'
    }
  },
  api: {
    customer: {
      index: `/api/customer`,
      couriers: `/api/customer/couriers`,
      couriersConnected: `/api/customer/connected-courier`,
      courierId: `/api/customer/:courierId`,
      courierIdAccountId: `/api/customer/courier/:courierId/:accountId`,
      courierIdAccountIdActive: `/api/customer/courier/:courierId/:accountId/active`,
      courierIdAccountIdDeactive: `/api/customer/courier/:courierId/:accountId/deactive`,
      generateWebhookLink: `/api/customer/webhook-gen/:courierId/:accountId`,
      setDefaultCourierAccount: `/api/customer/set-default-courier-account`,
      cloneCourier: `/api/customer/clone-courier`,
      cloneDefaultCourier: `/api/customer/clone-courier/default`,
      addressBook: `/api/customer/address`,
      addressBookId: `/api/customer/address/:addressId`,
      webhookLink: `/api/customer/webhook/:courierId/:accountId`,
      getPriceListCourier: `/api/customer/couriers-price-list`,
      searchAddress: `/api/customer/search-address`,
    },
    order: {
      add: '/api/order/add',
      update: '/api/order/update',
      updateAfterSendBill: '/api/order/update/after-send-bill',
      updateStatus: '/api/order/{id}/status/{statusCode}',
      updateMultipleStatus: '/api/order/status',
      getById: '/api/order/get-by-id',
      search: '/api/order/search',
      delete: '/api/order/delete',
      deleteMulti: '/api/order/delete-multi',
      cancel: '/api/order/{id}/cancel',
      sendBill: '/api/order/{id}/sendbill',
      confirmDeliveried: '/api/order/orders-confirm-delivery',

      // status
      orderStatus: `/api/order/{id}/status`,
      orderCourierStatus: `/api/order/{id}/courier-status`,
      definedStatus:`/api/order/defined-status`,

      // label
      documents: {
        printListLabels: `/api/order/doc/print/label`,
        printLabel: `/api/order/{orderId}/doc/print/label`,
        getLabelUrl: `/api/order/{orderId}/doc/url/label`,
        allDocUrl: `/api/order/{orderId}/doc/url/all`,
        allDocBase64: `/api/order/{orderId}/doc/base64/all`,
        createOrderReport: `/api/order/doc/shipment-report`,
        deleteOrderDocument: `/api/order/{orderId}/doc/{documentId}`
      },

      // 
      infoFromCourier: `/api/order/{orderId}/from-courier`,
    },
    location: {
      autoComplete: '/api/location/auto-complete',
      getFullAddress: '/api/location/get-full-address',
      get: `api/location/{type}/{parentId}`,
      getAllOptionLocation: `/api/location/get-all-option-locations`,
      mapping: {
        locationTpl: `/api/location/location-mapping-tpl`,
        locationCourier: `/api/location/location-mapping-courier`,
        autoMap: `/api/location/auto-map`,
        autoMapAll: `/api/location/auto-map-all`,
        forceMap: `/api/location/map`,
      }
    },
    courier: {
      index: `/api/courier`,
      getForConnect: `/api/courier/customer-connect`,
      getAll: `/api/courier/get-all`,
      getCustomerCourierByCustomerId: `/api/courier/customer-courier/get-by-customer`,
      getSystemCourier: `/api/courier/system-account`,
    },
    courierShippingService: {
      getByCourierId: `/api/CourierShippingService/get-by-courierId`,
    },
    courierAddService: {
      getByShippingId: `/api/CourierAddService/get-by-shippingId`,
      getByCourierId: `/api/CourierAddService/get-by-courierId`,
    },
    track: {
      trackByCode: `/api/Track/track`,
    },
    configuration: {
      getBMajor: `/api/configuration/major`,
      getBMinor: `/api/configuration/major/{majorCode}/minor`,
      getBConfig: `/api/configuration/major/{majorCode}/minor/{minorCode}/config`,
    }
  },
}
export default appSettings
