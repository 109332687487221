import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../../../setup/redux/Store'
import { TabIndexEnum } from '../models/TabIndexEnum'

export interface CourierConnectPopupState {
  visible: boolean
  loading: boolean
  success: boolean
}

const initPopupState: CourierConnectPopupState = {
  loading: false,
  visible: false,
  success: false,
}

export interface CourierConnectState {
  loading: boolean
  success: boolean
  // useSystemAccount: boolean
  tabIndex: TabIndexEnum

  DHLJP_State: CourierConnectPopupState
  EMS_State: CourierConnectPopupState
  PCSVN_State: CourierConnectPopupState
  VNPOST_State: CourierConnectPopupState
  GHN_State: CourierConnectPopupState
  VTPOST_State: CourierConnectPopupState
  GHTK_State: CourierConnectPopupState
  JANPOST_State: CourierConnectPopupState
}

// 02. Init State
const initState: CourierConnectState = {
  loading: false,
  success: false,
  // useSystemAccount: false,
  tabIndex: TabIndexEnum.UserInputAccount,
  EMS_State: {...initPopupState},
  VNPOST_State: {...initPopupState},

  DHLJP_State: {...initPopupState},
  PCSVN_State: {...initPopupState},
  GHN_State: {...initPopupState},
  VTPOST_State: {...initPopupState},
  GHTK_State: {...initPopupState},
  JANPOST_State: {...initPopupState},
}
// 03. slice store
export const courierConnectSlice = createSlice({
  name: 'courierConnect',
  initialState: initState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setSuccess: (state, action: PayloadAction<boolean>) => {
      state.success = action.payload
    },
    resetState: (state, action) => {
      state.loading = false
      state.success = false
    },
    // setUseSystemAccount: (state, action: PayloadAction<boolean>) => {
    //   state.useSystemAccount = action.payload;
    // },
    setAddAccountTab: (state, action: PayloadAction<TabIndexEnum>) => {
      state.tabIndex = action.payload;
    },

    setEMSConnectVisible: (state, action: PayloadAction<boolean>) => {
      state.EMS_State.visible = action.payload
    },
    setEMSConnectLoading: (state, action: PayloadAction<boolean>) => {
      state.EMS_State.loading = action.payload
    },
    setEMSConnectReset: (state, action: PayloadAction<boolean>) => {
      state.EMS_State.loading = false
      state.EMS_State.success = false
      if (action.payload) state.EMS_State.visible = false
    },

    setVNPOSTConnectVisible: (state, action: PayloadAction<boolean>) => {
      state.VNPOST_State.visible = action.payload
    },
    setVNPOSTConnectLoading: (state, action: PayloadAction<boolean>) => {
      state.VNPOST_State.loading = action.payload
    },
    setVNPOSTConnectReset: (state, action) => {
      state.VNPOST_State.loading = false
      state.VNPOST_State.success = false
    },
  },
})

//  export actions
export const actions = courierConnectSlice.actions

export const selectCourierConnectState = (state: RootState) => state.courierConnect
export const selectConnect_Ems_State = (state: RootState) => state.courierConnect.EMS_State
export const selectConnect_VnPost_State = (state: RootState) => state.courierConnect.VNPOST_State
export const selectConnect_DhlJp_State = (state: RootState) => state.courierConnect.DHLJP_State
export const selectConnect_Pcs_State = (state: RootState) => state.courierConnect.PCSVN_State
export const selectConnect_Ghn_State = (state: RootState) => state.courierConnect.GHN_State
export const selectConnect_Ghtk_State = (state: RootState) => state.courierConnect.GHTK_State
export const selectConnect_JanPost_State = (state: RootState) => state.courierConnect.JANPOST_State
export const selectConnect_VtPost_State = (state: RootState) => state.courierConnect.VTPOST_State

export default courierConnectSlice.reducer
