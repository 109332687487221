/* eslint-disable import/no-anonymous-default-export */

import appSettings from '../../settings/AppSettings'
import {appAxios} from '../../setup/axios/SetupAxios'
import {LocationType, LocationViewModel} from '../models/model/location.model'
import {BaseEntityResult} from '../models/responses/base.response'

const api = appSettings.api.location

export default {
  getLocationAsync: async (locationType: LocationType, parentId?: string | undefined | null, q?: string) => {
    return await appAxios.get<BaseEntityResult<LocationViewModel[]>>(
      api.get.replace('{type}', locationType).replace('{parentId}', parentId ?? ''),
      {
        params: {
          q,
        },
      }
    )
  },
}
