import React from 'react'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { MegaMenu } from './MegaMenu'
import { useIntl } from 'react-intl'
import { CouriersMegaMenu } from './mega-menu/CouriersMegaMenu'
import { OrderMegaMenu } from './mega-menu/OrderMegaMenu'
import appSettings from '../../../../settings/AppSettings'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      {/* <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to={appSettings.routes.home} /> */}
      <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to={appSettings.routes.dashboard.index} />

      {/* Order */}
      {/* <MenuInnerWithSub
        isMega={true}
        title={intl.formatMessage({ id: 'MENU.ORDER' })}
        to={appSettings.routes.order.index}
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <OrderMegaMenu />
      </MenuInnerWithSub> */}

      <MenuItem title={intl.formatMessage({ id: 'MENU.ORDER' })} to={appSettings.routes.order.list} />

      {/* End: Order */}

      <MenuItem title={intl.formatMessage({ id: 'MENU.COURIER_CONNECT' })} to={appSettings.routes.courier.index} />
      <MenuItem title={intl.formatMessage({ id: 'MENU.ADDRESS_BOOK' })} to={appSettings.routes.addressBook.index} />
      
      <MenuItem title={intl.formatMessage({ id: 'MENU.MAP_LOCATION' })} to={'/account/location-mapping'} />
    </>
  )
}
