export class LocationViewModel {
  id: string
  code: string | null
  name: string | null
  locationType: LocationType
  parentId: string | null
  postCode: string | null
  phoneCode: string | null
  extCd1: string | null
  extTxt1: string | null
  extCd2: string | null
  extTxt2: string | null
  extCd3: string | null
  extTxt3: string | null
}

export class LocationFullViewModel {
    country: LocationViewModel;
    city: LocationViewModel;
    district: LocationViewModel;
    ward: LocationViewModel;
}

// todo move to common filed
export enum LocationType {
  Country       = 'C',
  District      = 'D',
  StateProvince = 'S',
  Ward          = 'W',
}
